@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '_page-header-typography.scss';
@import 'material-overrides/_tab-typography.scss';
@import 'material-overrides/_mat-icons-typography.scss';

@mixin typography($typography-config) {

    @include mat.all-component-typographies($typography-config); //Set the typography.  https://material.angular.io/guide/typography
    @include tab-typography($typography-config);
    @include mat-icons-typography($typography-config);
    @include page-header-typography($typography-config);
    @include mat.typography-level($typography-config, body-1);
    /*
        Make this first because it's our default size.  that way if we override the size somewhere it will take the new size properly
    */
    .iq-body-2 {
        font-size: mat.font-size($typography-config, body-2);
        font-weight: mat.font-weight($typography-config, body-2);
        line-height: mat.line-height($typography-config, body-2);

        .material-icons {
            font-size: mat.font-size($typography-config, body-2);
            font-weight: mat.font-weight($typography-config, body-2);
            line-height: mat.line-height($typography-config, body-2);
            height: mat.font-size($typography-config, body-2);
            width: mat.font-size($typography-config, body-2);
        }
    }


    .iq-display-1 {
        font-size: mat.font-size($typography-config, display-1);
        font-weight: mat.font-weight($typography-config, display-1);
        line-height: mat.line-height($typography-config, display-1);

        .material-icons {
            font-size: mat.font-size($typography-config, display-1);
            font-weight: mat.font-weight($typography-config, display-1);
            line-height: mat.line-height($typography-config, display-1);
            height: mat.font-size($typography-config, display-1);
            width: mat.font-size($typography-config, display-1);
        }
    }

    .iq-display-2 {
        font-size: mat.font-size($typography-config, display-2);
        font-weight: mat.font-weight($typography-config, display-2);
        line-height: mat.line-height($typography-config, display-2);

        .material-icons {
            font-size: mat.font-size($typography-config, display-2);
            font-weight: mat.font-weight($typography-config, display-2);
            line-height: mat.line-height($typography-config, display-2);
            height: mat.font-size($typography-config, display-2);
            width: mat.font-size($typography-config, display-2);
        }
    }

    .iq-display-3 {
        font-size: mat.font-size($typography-config, display-3);
        font-weight: mat.font-weight($typography-config, display-3);
        line-height: mat.line-height($typography-config, display-3);

        .material-icons {
            font-size: mat.font-size($typography-config, display-3);
            font-weight: mat.font-weight($typography-config, display-3);
            line-height: mat.line-height($typography-config, display-3);
            height: mat.font-size($typography-config, display-3);
            width: mat.font-size($typography-config, display-3);
        }
    }

    .iq-display-4 {
        font-size: mat.font-size($typography-config, display-4);
        font-weight: mat.font-weight($typography-config, display-4);
        line-height: mat.line-height($typography-config, display-4);

        .material-icons {
            font-size: mat.font-size($typography-config, display-4);
            font-weight: mat.font-weight($typography-config, display-4);
            line-height: mat.line-height($typography-config, display-4);
            height: mat.font-size($typography-config, display-4);
            width: mat.font-size($typography-config, display-4);
        }
    }

    .iq-title {
        font-size: mat.font-size($typography-config, title);
        font-weight: mat.font-weight($typography-config, title);
        line-height: mat.line-height($typography-config, title);

        .material-icons {
            font-size: mat.font-size($typography-config, title);
            font-weight: mat.font-weight($typography-config, title);
            line-height: mat.line-height($typography-config, title);
            height: mat.font-size($typography-config, title);
            width: mat.font-size($typography-config, title);
        }
    }

    .iq-subheading-1 {
        font-size: mat.font-size($typography-config, subheading-1);
        font-weight: mat.font-weight($typography-config, subheading-1);
        line-height: mat.line-height($typography-config, subheading-1);

        .material-icons {
            font-size: mat.font-size($typography-config, subheading-1);
            font-weight: mat.font-weight($typography-config, subheading-1);
            line-height: mat.line-height($typography-config, subheading-1);
            height: mat.font-size($typography-config, subheading-1);
            width: mat.font-size($typography-config, subheading-1);
        }
    }

    .iq-subheading-2 {
        font-size: mat.font-size($typography-config, subheading-2);
        font-weight: mat.font-weight($typography-config, subheading-2);
        line-height: mat.line-height($typography-config, subheading-2);

        .material-icons {
            font-size: mat.font-size($typography-config, subheading-2);
            font-weight: mat.font-weight($typography-config, subheading-2);
            line-height: mat.line-height($typography-config, subheading-2);
            height: mat.font-size($typography-config, subheading-2);
            width: mat.font-size($typography-config, subheading-2);
        }
    }

    .iq-body-1 {
        font-size: mat.font-size($typography-config, body-1);
        font-weight: mat.font-weight($typography-config, body-1);
        line-height: mat.line-height($typography-config, body-1);

        .material-icons {
            font-size: mat.font-size($typography-config, body-1);
            font-weight: mat.font-weight($typography-config, body-1);
            line-height: mat.line-height($typography-config, body-1);
            height: mat.font-size($typography-config, body-1);
            width: mat.font-size($typography-config, body-1);
        }
    }



    .iq-caption {
        font-size: mat.font-size($typography-config, caption);
        font-weight: mat.font-weight($typography-config, caption);
        line-height: mat.line-height($typography-config, caption);

        .material-icons {
            font-size: mat.font-size($typography-config, caption);
            font-weight: mat.font-weight($typography-config, caption);
            line-height: mat.line-height($typography-config, caption);
            height: mat.font-size($typography-config, caption);
            width: mat.font-size($typography-config, caption);
        }
    }

    .iq-button {
        font-size: mat.font-size($typography-config, button);
        font-weight: mat.font-weight($typography-config, button);
        line-height: mat.line-height($typography-config, button);

        .material-icons {
            font-size: mat.font-size($typography-config, button);
            font-weight: mat.font-weight($typography-config, button);
            line-height: mat.line-height($typography-config, button);
            height: mat.font-size($typography-config, button);
            width: mat.font-size($typography-config, button);
        }
    }

    .iq-input {
        font-size: mat.font-size($typography-config, input);
        font-weight: mat.font-weight($typography-config, input);
        line-height: mat.line-height($typography-config, input);

        .material-icons {
            font-size: mat.font-size($typography-config, input);
            font-weight: mat.font-weight($typography-config, input);
            line-height: mat.line-height($typography-config, input);
            height: mat.font-size($typography-config, input);
            width: mat.font-size($typography-config, input);
        }
    }

    .iq-margin-top-mat-form-bottom {
        //memic the space material puts on the bottom of it's form controls, but on the top.  This way we can put mutiple text (or non material items) in with form controls and have spacing not look horrid
        margin-top: 1.25em; //Material does some sort of math to figure this out...this is not perfect if we change font sizes, but for now it's fine.
    }

    .iq-margin-mat-form-bottom {
        //memic the space material puts on the bottom of it's form controls, but on the top.  This way we can put mutiple text (or non material items) in with form controls and have spacing not look horrid
        margin-bottom: 1.25em; //Material does some sort of math to figure this out...this is not perfect if we change font sizes, but for now it's fine.
    }
}
