//  Shared styles for "entry forms" - i.e. Ticket and Project entry.
//  Ideally, use a general class name like "iq-entry-form" in case we make other forms like this somewhere else.
//  But handles all of the "iq-ticket-entry" classes so we don't have to change them all.

//  *** Autocomplete styles are in MaterialStyleOverrides which is included globally.
//  They key off: .iq-autocomplete, .iq-ticket-entry, .iq-entry-form

.iq-ticket-entry-form, .iq-entry-form {
    .iq-section-group {
        //  Both of these are needed so that placeholder text on matInput controls do not overlap the header text
        .header.edit {
            margin-bottom: 10px !important;
        }

        .content {
            padding-top: 5px !important;
            padding-left: 20px !important;
        }
    }
}

.iq-ticket-entry-section, .iq-entry-form-section {
    .iq-section-item-view, .iq-section-item-edit {
        label, div.label {
            padding-right: 10px;
        }
    }

    .iq-section-group {
        div.label {
            padding-right: 10px;
        }
    }

    .mat-form-field-disabled {
        input, .mat-select, textarea {
            background-color: #F2F2F2 !important;
        }
    }

    .mat-form-field-label :not(.mat-form-field-label) {
        font-style: italic;
    }

    .mat-form-field-label-wrapper {
        top: -0.9em;
    }

    .mat-form-field-infix {
        //  Angular Material sets this to 180px which prevents (or at least makes it very very difficult) to change
        //  the size of the field.  Especially if you want to make it smaller.
        width: auto;
        border-top: 0px;
        padding: 0.3em 0; //  default = 0.4375em
    }
    //  Put the class "expand-for-error" on a <mat-form-field> element if it should expand itself when displaying an error.
    //  This is necessary if the field below it has a floating label or if the error message expands to multiple lines - it keeps
    //  it from overlapping with the control below it.
    //  From: https://github.com/angular/components/issues/5227
    .expand-for-error.mat-form-field.mat-form-field-invalid {
        .mat-form-field-wrapper {
            padding-bottom: 1em !important;

            .mat-form-field-underline {
                position: initial !important;
                display: block !important;
                margin-top: -1px !important;
            }

            .mat-form-field-subscript-wrapper, .mat-form-field-ripple {
                position: initial !important;
                display: table !important;
            }

            .mat-form-field-subscript-wrapper {
                min-height: calc(1em + 1px) !important;
            }
        }
    }
    //  Just like "expand-for-error" (above) except that this ALWAYS leaves room for the mat-error.  This helps prevent
    //  the page from being jumpy...
    .leave-room-for-error.mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 1em !important;

            .mat-form-field-underline {
                position: initial !important;
                display: block !important;
                margin-top: -1px !important;
            }

            .mat-form-field-subscript-wrapper, .mat-form-field-ripple {
                position: initial !important;
                display: table !important;
            }

            .mat-form-field-subscript-wrapper {
                min-height: calc(1em + 1px) !important;
            }
        }
    }

    .mat-chip-list-wrapper {
        //  Material puts a -4 margin on this which jacks up all of the width calculations on the meeting dialog (causes a scrollbar!)
        margin: unset;
    }
}
