//  Global styles used by iq-entry-form - including ticket entry and project entry

@use '@angular/material' as mat;

//  This $highlight-color is the $accent/lighter color from the green them (which is yellow but slightly softer then the standard yellow).
//  It is hardcoded to make it consistent in all the themes (and because the irth theme would result in showing as dark blue).
$highlight-color: #F3FF62;

@mixin entry-form-theme($theme, $grayFontTheme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $grayFontPrimary: map-get($grayFontTheme, primary);
    $grayFontAccent: map-get($grayFontTheme, accent);

    //  TODO: Replace "iq-ticket-entry..." class names with just "iq-entry-form...".
    //  For now, both style names are handled here so we don't break everything in ticket entry.

    .iq-ticket-entry-label, .iq-entry-form-label {
        color: mat.get-color-from-palette($grayFontPrimary);
    }

    .iq-ticket-entry-section, .iq-entry-form-section {

        .iq-section-item-view, .iq-section-item-edit {
            .view, .edit {
                label, .label {
                    color: mat.get-color-from-palette($grayFontPrimary);
                }
            }
        }

        .iq-section-group {
            div.label {
                color: mat.get-color-from-palette($grayFontPrimary);
            }
        }

        .mat-form-field-label :not(.mat-form-field-label) {
            color: mat.get-color-from-palette($grayFontPrimary);
        }

        .mat-form-field-invalid .mat-input-element,
        .mat-form-field-invalid .mat-select {
            background-color: mat.get-color-from-palette($warn, lighter);
            color: mat.get-color-from-palette($warn, lighter-contrast);
        }

        .ng-select.ng-invalid:not(.ng-untouched) {
            .ng-input {
                background-color: mat.get-color-from-palette($warn, lighter);
                color: mat.get-color-from-palette($warn, lighter-contrast);
            }
        }

        .mat-form-field.mat-focused {
            .mat-form-field-flex { //  .mat-form-field-label-wrapper will include the placeholder but then it COVERS the input so you can't see what has been entered!
                //  Not using the $accent/lighter color from the theme because then the "irth" theme shows in the background as a dark blue.
                //  This $highlight-color is the $accent/lighter color from the green them (which is yellow but slightly softer then the standard yellow).
                //background-color: mat.get-color-from-palette($accent, lighter);
                background-color: $highlight-color;
            }
        }

        .ng-select-focused {
            //  .ng-input is the div inside this one, but it's exactly the size of the input.
            //  This container is bigger and includes the placeholder (which is not good).  But this is the only way to show
            //  the focus color (yellow) and also the error color (red) if both apply.  Otherwise, only red shows so it's difficult to
            //  see where the focus is.
            .ng-select-container {
                //  Not using the $accent/lighter color from the theme because then the "irth" theme shows in the background as a dark blue.
                //  This $highlight-color is the $accent/lighter color from the green them (which is yellow but slightly softer then the standard yellow).
                //background-color: mat.get-color-from-palette($accent, lighter);
                background-color: $highlight-color;
            }
        }

        .hide-when-disabled.mat-input-element:disabled,
        .hide-when-disabled.mat-select-disabled .mat-select-value {
            color: #F2F2F2 !important;
        }
    }
}
