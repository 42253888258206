@use '@angular/material' as mat;
@mixin main-nav-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .mat-sidenav-container {

        .mat-drawer {
            background-color: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, "default-contrast");

            .mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item,
            .mat-nav-list .mat-list-item.mat-list-item-focus {
                background-color: mat.get-color-from-palette($primary, "lighter");
                color: mat.get-color-from-palette($primary, "lighter-contrast");
                border: 1px solid mat.get-color-from-palette($primary);
                border-top: 0px;
            }

            .mat-nav-list .active-link {
                .mat-list-item-content {
                    background-color: mat.get-color-from-palette($primary, "darker");
                    color: mat.get-color-from-palette($primary, "darker-contrast");
                }
            }

            .mat-nav-list .mat-list-item:hover /*, .mat-list-item-focus*/ {
                background-color: mat.get-color-from-palette($accent) !important;
                color: mat.get-color-from-palette($accent, 'default-contrast');
            }
        }

        .mat-sidenav-content {
            background-color: white;
        }
    }

    .iq-phone-main-menu {
        .mat-nav-list .active-link {
            .mat-list-item-content {
                background-color: mat.get-color-from-palette($primary, "darker");
                color: mat.get-color-from-palette($primary, "darker-contrast");
            }
        }
    }
}
