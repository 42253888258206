
/* this changes the padding on the mat dialogs to 10px instead of 24px */
/* And sets a rounded border radius */
.cdk-overlay-container {
    .mat-dialog-container {
        //border-radius: 10px;
        padding: 10px;

        .mat-datepicker-content {
            margin: -10px;
        }

        .mat-dialog-content {
            margin: unset;
            padding: unset;
        }

        .mat-dialog-actions {
            padding-bottom: unset;
            justify-content: flex-end;
        }

        .mat-dialog-actions:last-child {
            margin-bottom: unset;
        }
    }
}

.mat-dialog-title {
    margin: 0 0 15px !important;
    display: block;
    padding-left: 5px;
    cursor: move;
}

.mat-dialog-actions {
    margin-top:20px;
}

.iq-grab{
    cursor: grab;
}
.iq-grabbing{
    cursor: grabbing;
}

.dialog-fill-height {
    height: 100%;
    display: flex;
    flex-direction: column;

    .mat-dialog-title {
        flex-shrink: 0;
    }

    .mat-dialog-content {
        flex-grow: 1;
        max-height: unset;
    }

    .mat-dialog-actions {
        justify-content: center;
        flex-shrink: 0;
    }
}

.mat-dialog-actions.centered-dialog-buttons {
    justify-content: center !important;

    & > button, iq-icon-button {
        //  Don't use % - it does not allow the buttons to cause the dialog to expand and causes the buttons
        //  to overlap each other.
        margin-left: 2em !important;
        margin-right: 2em !important;
    }
}

//  Can use addPanelClass() on MatDialogRef to dynamically set dialog to max size (with some buffer on the edges)
.dialog-max-size {
    height:90%;
    max-width:95% !important;
    width:95%;
}
