@use '@angular/material' as mat;

@mixin tabs-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $activeBackgroundColor: mat.get-color-from-palette($primary, 50); // white
    mat-tab-group.iq-tab-style {
        .mat-tab-body-wrapper {
            background-color: $activeBackgroundColor;
        }

        .mat-tab-label-container {

            .mat-tab-list {

                .mat-tab-labels {
                    //  Default text color is black
                    .mat-tab-label {
                        background-color: mat.get-color-from-palette($primary, 800); //  light gray
                    }
                    //Make the background of the active tab white
                    .mat-tab-label.mat-tab-label-active {
                        background-color: $activeBackgroundColor;
                    }
                }
            }
        }
    }
    //  Tabs that fall inside another set of Tabs.  Override styles set above to make them look a little different.
    //  Because these tabs are inside other tabs, they are ALWAYS going to pick up those default styles.
    //  (tried preventing that with :not(.iq-child-tabs) but that didn't work).
    //  So must override or unset everything above or it will be inherited here.
    mat-tab-group.iq-tab-style.iq-child-tabs {
        .mat-tab-label-container {
            .mat-tab-list {
                mat-ink-bar {
                    background-color: mat.get-color-from-palette($accent);
                }

                .mat-tab-labels {
                    .mat-tab-label {
                        background-color: $activeBackgroundColor;
                    }

                    .mat-tab-label.mat-tab-label-active {
                        color: mat.get-color-from-palette($primary);
                    }
                }
            }
        }
    }
}
