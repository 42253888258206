//  This is needed for: https://github.com/matheo/angular/tree/master/libs/datepicker
@use '@angular/material' as mat;
@use '@matheo/datepicker/theming' as datepicker;

//  Custom Theming for NGrid Material
//  There are several other places below that add in additional palettes to the nGrid themes.  Search for "ngrid".
@use '@pebula/ngrid' as ngrid;
@use '@pebula/ngrid-material' as ngrid-material;


//
//
//
//  DO NOT IMPORT THIS INTO OTHER SCSS FILES! (other than the base styles.scss)
//  It will cause duplicate styles. See https://github.com/angular/material2/blob/master/guides/theming.md (about middle of page)
//
//
//


//  Angular Themes links:
//      How to setup multiple themes: https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
//                       github repo: https://github.com/tomastrajan/angular-ngrx-material-starter
//      Angular Material Docs: https://github.com/angular/material2/blob/master/guides/theming.md
//      Theming Custom Components: https://github.com/angular/material2/blob/master/guides/theming-your-components.md

///
// code for the mat-palette, mat-theme, etc to know what you can do https://github.com/angular/material2/blob/master/src/lib/core/theming/_theming.scss
///

@import '@angular/material/theming';
@import 'typography/typography-configs.scss';
@import 'typography/typography-classes.scss';
@import 'typography/_section-group-padding.scss';
@import '../app/Shared/Components/Controls/Emails/_EmailListSection-typography.component.scss';
@import '../app/Shared/Components/Controls/Phone/ListSection/_PhoneListSection-typography.component.scss';

@import 'color/palettes/green/green.scss';
@import 'color/palettes/blue/blue.scss';
@import 'color/palettes/gray/gray.scss';
@import 'color/palettes/irth/irth-darkMenuAltColorButtons.scss';

@import 'color/control-themes/_primary-color-theme.scss';
@import 'color/control-themes/_main-nav-theme.scss';

@import 'typography/ng-select-typography.scss';
@import 'color/control-themes/_ng-select-theme.scss';

//  todo: All of these need to be moved into theme files within the custom component (like below):
@import 'color/control-themes/_app-busy-theme.scss';
@import 'color/control-themes/_tab-theme.scss';
@import 'color/control-themes/_dialog-theme.scss';
@import 'color/control-themes/_page-header-theme.scss';
@import 'color/control-themes/_actionable-items-theme.scss';

// Imports for custom component themes:
@import '../app/Shared/Components/Autocomplete/County/_CountyAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Autocomplete/Place/_PlaceAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Autocomplete/Street/_StreetAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Autocomplete/ExcavatorCompany/_ExcavatorCompanyAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Autocomplete/ExcavatorOffice/_ExcavatorOfficeAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Autocomplete/ExcavatorContact/_ExcavatorContactAutocompleteOption.component.theme.scss';
@import '../app/Shared/Components/Controls/Section/Group/_SectionGroup-theme.component.scss';
@import '../app/Shared/Components/Controls/IconButton/_IconButton.component-theme.scss';
@import '../app/Shared/Components/Maps/Controls/Search/Autocomplete/Feature/_FeatureAutocompleteOption.component.theme.scss';

@import '../app/Shared/EntryFields/Styles/EntryForms-theme.scss';
@import '../app/Pages/Tickets/Details/_TicketDetails-theme.component.scss';
@import '../app/Shared/AddNewForms/OpenTimes/_OpenTime.component.scss';

//  Suppressing errors about duplicate styles.  May want to enable this and follow the instructions in the warnings though.
//  (and the warnings have nothing to do with the datepicker being a different angular version - happens when it's the same version too)
//https://github.com/angular/components/blob/master/guides/duplicate-theming-styles.md
$mat-theme-ignore-duplication-warnings: true;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($iq-typography-default);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$typographies: (default: ($iq-typography-default, 120),
                 small: ($iq-typography-small, 110),
                 large: ($iq-typography-large, 130));

@each $size, $config in $typographies{
    .iq-typography-#{$size} {
        @include typography(nth($config, 1));
        @include section-group-padding(nth($config, 2));
        @include open-time-typography(nth($config, 2));
        @include ng-select-typography(nth($config, 1));
        //@include positive-response-page-typography(nth($config, 2))
    }

    //Set the typography to override the site settings when picking the one to use by the user
    .iq-typography-picker {
        .iq-typography-#{$size} {
            @include typography(nth($config, 1));
            @include ngrid.ngrid-typography(nth($config, 1));
        }
    }
}

//Need to set a default theme for material stuff so we don't get a warning about one not being set.  They are overrriden by the classes we use below though
$defaultTheme: mat.define-light-theme(mat.define-palette($fouriq-green, 100, 200, 300), mat.define-palette($fouriq-green, 400, 500, 600), mat.define-palette(mat.$red-palette));
@include mat.all-component-themes($defaultTheme);

//  If need more color palettes, add as comma separated list like this:
//      a: ( $fouriq-green-1, $fouriq-green-2, $fouriq-green-3 ),
//  and reference like this "nth($palettes, 1)" where "1" is the 1-based index to reference.
//  For some reason, sass blows up if we define something like "a: ( $fouriq-green-main )" but doesn't if we add the trailing ,
$colors: ( fouriq: (
                    //  if more are added, make sure to add support in app.component.html for the color picker
                    "green": ( $fouriq-green, ),
                    "blue": ( $fouriq-blue, ),
                    "gray": ( $fouriq-gray, ),
                    "irth": ( $fouriq-irth, )
                   )
          );

@mixin custom-components-theme($theme, $grayFontTheme) {
    @include dialog-theme($theme);
    @include excavator-autocomplete-option($theme);
    @include section-group($theme);
    @include icon-button($theme);
    @include tabs-theme($theme);
    @include ng-select-theme($theme, $grayFontTheme);
    @include datepicker.theme($theme);
}

@each $client, $configs in $colors{
    .client-#{$client} {
        @each $config, $palettes in $configs {
            //  We have 1 color palette defined for each theme.  It contains all of the various colors that we use in the app.
            //  We use this 1 color palette, to construct variations below.  Angular expects a palette to contain a range of
            //  colors where "500" is the default/base color.  Lower numbers are lighter, higher numbers are darker.
            //  But it really only needs 3 colors - the default, light, and dark colors.  So our single color palette defines
            //  those 3 variations for the main colors in a theme.  And then we use "mat-palette" to construct a full palette
            //  from the 3 shades.
            //
            //  The proper way to do this is to create a color palette for EACH color (using the http://mcg.mbitson.com/) so that
            //  we have the full range of shades for each of our colors.  But what we're doing works fine (and no idea why we would
            //  need all of those shades any way) and then we only have 1 palette that needs to be configured for each theme.
            //
            //  If we need additional colors, can add more color palettes to the $colors list above - we used to have multiple
            //  there but the others are easily constructed so they were not needed.  Can reference additional by changing the
            //  index in this: nth($palettes, [index])
            //
            //  The palette is expected to contain colors like this:
            //        50              background
            //        100             primary
            //        200             primary light
            //        300             primary dark
            //        400             secondary
            //        500             secondary light
            //        600             secondary dark
            //        700             gray
            //        800             light gray
            //        900             dark gray
            //        A100            even darker gray
            //        A200            accent
            //        A400            accent light
            //        A700            accent dark
            // The main color palettes
            $primary-palette: mat.define-palette(nth($palettes, 1), 100, 200, 300); //  Order is default, lighter, darker
            $secondary-palette: mat.define-palette(nth($palettes, 1), 400, 500, 600);
            $accent-palette: mat.define-palette(nth($palettes, 1), A200, A400, A700);
            $gray-palette: mat.define-palette(nth($palettes, 1), 700, 800, 900);
            $warn-palette: mat.define-palette(mat.$red-palette);

            //main navigation colors
            $main-nav-primary: mat.define-palette(nth($palettes, 1), 100, 200, A200);
            $main-nav-accent: mat.define-palette(nth($palettes, 1), 600, 200, 300); //default color for hovering color
            $main-nav-warn: $warn-palette;

            //page header colors
            $page-header-primary: mat.define-palette(nth($palettes, 1), 800, 700, 900);
            $page-header-accent: $primary-palette;
            $page-header-warn: $warn-palette;

            //busy indicator colors
            $busy-primary: $primary-palette;
            $busy-accent: mat.define-palette(nth($palettes, 1), 300, 200, 300);
            $busy-warn: $warn-palette;

            $ngrid-material-primary: $primary-palette;
            $ngrid-material-accent: $accent-palette;
            $ngrid-material-warn: $warn-palette;
            $ngrid-material-theme: mat.define-light-theme($ngrid-material-primary, $ngrid-material-accent, $ngrid-material-warn);
            $ngrid-material-theme: ngrid.define-light-theme($ngrid-material-theme);

            //Gray fonts
            $gray-font-primary: $gray-palette;
            $gray-font-accent: mat.define-palette(nth($palettes, 1), 900, 50, A100); //Overlaps, but want to have the darker color here.
            &.color-palette-#{$config},
            .color-palette-#{$config} {
                //Set themes to the colors defined above
                @include mat.all-component-themes(mat.define-light-theme($primary-palette, $secondary-palette, $warn-palette));

                @include actionable-items(mat.define-light-theme($accent-palette, $secondary-palette, $warn-palette));

                @include primary-color-theme(mat.define-light-theme($primary-palette, $accent-palette, $warn-palette), mat.define-light-theme($secondary-palette, $accent-palette, $warn-palette), mat.define-light-theme($gray-font-primary, $gray-font-accent, $warn-palette));

                @include main-nav-theme(mat.define-dark-theme($main-nav-primary, $main-nav-accent, $main-nav-warn));
                @include page-header-theme(mat.define-light-theme($page-header-primary, $page-header-accent, $page-header-warn));
                @include app-busy-theme(mat.define-light-theme($busy-primary, $busy-accent, $busy-warn));
                @include entry-form-theme(mat.define-light-theme($primary-palette, $accent-palette, $warn-palette), mat.define-light-theme($gray-font-primary, $gray-font-accent, $warn-palette));
                @include ticket-details(mat.define-light-theme($primary-palette, $accent-palette, $warn-palette), mat.define-light-theme($gray-font-primary, $gray-font-accent, $warn-palette));
                //  TODO: The custom components above should be moved into the below mixin so that we only need to @import them once
                //  per theme style.  But we are currently defining some custom palettes - that SHOULD be done inside the custom themes!
                //  mixin the custom component themes here so that we can @include this once per css class theme
                @include custom-components-theme(mat.define-light-theme($primary-palette, $secondary-palette, $warn-palette), mat.define-light-theme($gray-font-primary, $gray-font-accent, $warn-palette));

                @include ngrid-material.all-ngrid-material-theme($ngrid-material-theme);
            }
            //Set the color scheme to override the site settings when picking the one to use by the user
            .iq-palette-picker {
                .color-palette-#{$config} {
                    @include primary-color-theme(mat.define-light-theme($primary-palette, $accent-palette, $warn-palette), mat.define-light-theme($secondary-palette, $accent-palette, $warn-palette), mat.define-light-theme($gray-font-primary, $gray-font-accent, $warn-palette));
                }
            }
        }
    }
}

/*$ngrid-material-starter-primary: mat.define-palette(mat.$indigo-palette);
$ngrid-material-starter-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$ngrid-material-starter-warn: mat.define-palette(mat.$red-palette);
$ngrid-material-starter-theme: mat.define-light-theme($ngrid-material-starter-primary, $ngrid-material-starter-accent, $ngrid-material-starter-warn);
$ngrid-material-starter-theme: ngrid.define-light-theme($ngrid-material-starter-theme);
@include ngrid-material.all-ngrid-material-theme($ngrid-material-starter-theme);*/


//  todo: Should be creating css classes for each theme.  Then we can reference those classes (in the main app) to
//  dynamically switch between themes.
//  Like here: https://github.com/tomastrajan/angular-ngrx-material-starter/blob/master/src/styles.scss

/*
    Material themes tthat we can set as of 2-27-18 from https://github.com/angular/material2/blob/master/src/lib/core/theming/_all-theme.scss
    If you call angular-material-theme($theme) it will set all of these with that theme.  But we can also set each of these individually

    @include mat.core-theme($theme);
    @include mat.autocomplete-theme($theme);
    @include mat.badge-theme($theme);
    @include mat.bottom-sheet-theme($theme);
    @include mat.button-theme($theme);
    @include mat.button-toggle-theme($theme);
    @include mat.card-theme($theme);
    @include mat.checkbox-theme($theme);
    @include mat.chips-theme($theme);
    @include mat.table-theme($theme);
    @include mat.datepicker-theme($theme);
    @include mat.dialog-theme($theme);
    @include mat.divider-theme($theme);
    @include mat.expansion-theme($theme);
    @include mat.form-field-theme($theme);
    @include mat.grid-list-theme($theme);
    @include mat.icon-theme($theme);
    @include mat.input-theme($theme);
    @include mat.list-theme($theme);
    @include mat.menu-theme($theme);
    @include mat.paginator-theme($theme);
    @include mat.progress-bar-theme($theme);
    @include mat.progress-spinner-theme($theme);
    @include mat.radio-theme($theme);
    @include mat.select-theme($theme);
    @include mat.sidenav-theme($theme);
    @include mat.slide-toggle-theme($theme);
    @include mat.slider-theme($theme);
    @include mat.stepper-theme($theme);
    @include mat.tabs-theme($theme);
    @include mat.toolbar-theme($theme);
    @include mat.tooltip-theme($theme);
    @include mat.snack-bar-theme($theme);

*/
