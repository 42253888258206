
// Media queries.
$xsmall: 'max-width: 600px';
$small: 'max-width: 960px';

// Color palette.
$blue: #1976D2;
$accentblue: #1E88E5;
$brightred: #DD0031;
$darkred: #C3002F;
$white: #FFFFFF;
$offwhite: #FAFAFA;
$backgroundgray: #F1F1F1;
$lightgray: #DBDBDB;
$mist: #ECEFF1;
$mediumgray: #bcbcbc;
$darkgray: #333;
$black: #0A1014;
$orange: #FF9800;

$dimmedGrayText: gray;

$grayBorderColor: darkgray;
