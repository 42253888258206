@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin actionable-items($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @include mat.button-theme($theme);
    @include mat.button-toggle-theme($theme);
    @include mat.checkbox-theme($theme);
    @include mat.chips-theme($theme);
    @include mat.radio-theme($theme);
    @include mat.slide-toggle-theme($theme);
    @include mat.slider-theme($theme);

    .ngx-pagination .current {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, "default-contrast");
    }

    .actionable-background {
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-color-from-palette($primary, "default-contrast");

        .gray-primary-text, .link {
            color: mat.get-color-from-palette($primary, "default-contrast");
        }
    }

    .lbp-background, .extcircumstances-background {
        &:not(.verified) {
            background-color: mat.get-color-from-palette($primary);

            &, .gray-primary-text, .link {
                color: mat.get-color-from-palette($primary, "default-contrast");
            }
        }

        border: solid 1px mat.get-color-from-palette($primary);
    }

    .landlord-background {
        &:not(.verified) {
            background-color: mat.get-color-from-palette($accent);

            &, .gray-primary-text, .link {
                color: mat.get-color-from-palette($accent, "default-contrast");
            }
        }

        border: solid 1px mat.get-color-from-palette($accent);
    }
    //The material stuff doesn't use the contrast color for text colors (they use black).  So override that to always use the contrast
    //.mat-toolbar.mat-primary,
    .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        color: mat.get-color-from-palette($primary, "default-contrast") !important;
    }

    .mat-checkbox-checkmark-path {
        stroke: mat.get-color-from-palette($primary, "default-contrast") !important;
    }

    .mat-button-toggle-group {
        .mat-button-toggle:not(.mat-button-toggle-checked)  {
            //  Must also specify the width and type because angular has a "border-left: 1px solid" defined!
            border: 1.5px solid mat.get-color-from-palette($primary, "darker") !important;
        }

        .mat-button-toggle-checked {
            border: 1.5px solid mat.get-color-from-palette($primary) !important;
            background-color: mat.get-color-from-palette($primary);
            .mat-button-toggle-label-content {
                color: mat.get-color-from-palette($primary, "default-contrast") !important;
            }
        }
    }
}
