//  As of OpenLayers 7, the ol.css has colors defined as variables.  We can tweak them as below.
//  Their defaults are now a white background with gray as the primary color.  Which looks great but gets washed out a
//  bit on top of our map and those changes would require everyone to update their training material.
//  These overrides change everything back to the OpenLayers 6 theme.
//  See the top of node_modules\ol\ol.css for other options if necessary.
:root,
:host {
  --ol-background-color: #003c8880 !important;
  --ol-subtle-foreground-color: #fff !important;
}
//  And this one is defined in ol-ext and they changed it to a light blue
.ol-control.ol-bar .ol-toggle.ol-active > button, .ol-control.ol-bar .ol-toggle.ol-active button:hover {
    background-color: #3c8800b3 !important;
    color: var(--ol-subtle-foreground-color);
}

//  The map search button is not inside the openlayers control so it does not get the ol-touch class on a phone.
//  And the iq-device-size-phone is placed ON the body element so can't put this inside the body tag below.
.iq-device-size-phone .iq-map-search-button button {
    font-size: 1.5em !important;
}

//  Also need .cdk-overlay-container here so that the styles apply when the map is being shown in a dialog (i.e. the Verify Location dialog).
//  Otherwise, the map does not display at all when in full screen!
body, .cdk-overlay-container {
    .map {
        width: 100%;
        height: initial;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .ol-viewport {
            //  This (and the display:flex; flex-direction:column - above) are necessary for Safari & iOS.
            //  Without this, the map does not display at all!
            flex-grow: 1;
        }
    }

    .ol-control button {
        //  OpenLayers 6.7+ changed this from "1.14em" to "inherit" which reduced the size of the buttons on the map
        font-size: 1.14em;
    }
    //  Don't know why but ol-ext is changing this to 2.5 which makes the button huge on a phone
    .ol-touch .ol-layerswitcher>button {
        font-size: 1.5em;
    }

    .ol-mouse-position {
        bottom: 15px;
        top: unset;
    }

    .iq-switch-base-map-control {
        right: .5em;
        top: 3em;
        outline: none;

        button {
            div {
                font-size: 1.5em;
                position: relative;
                outline: none;
            }
        }
    }

    .ol-layerswitcher {
        button::before, button::after {
            //  Remove the before/after styles that create the weird looking button icon.
            //  When we create the LayerSwitcher, we inject the same character used by the ToggleBaseMapControl.
            background: inherit;
            box-shadow: inherit;
            top: inherit;
            left: inherit;
            content: inherit;
        }

        button {
            background-color: rgba(0,60,136,.5);
            font-size: 1.14em;

            div {
                font-size: 1.5em;
                position: relative;
                outline: none;
            }

            ::hover {
                background-color: rgba(0,60,136,.7);
            }
        }

        .collapse-layers {
            //  Hide the collapse button on a group inside the layer switcher.
            display: none;
        }

        .ol-layer-group > .li-content label:after,
        .ol-layer-group > .li-content label:before {
            //  Hide checkbox that preceeds a group in the layer switcher
            //  https://github.com/Viglino/ol-ext/issues/145
            content: unset;
        }

        .ol-layer-group > .li-content label {
            //  Prevent click on the checkbox that preceeds a group in the layer switcher
            //  https://github.com/Viglino/ol-ext/issues/145
            padding-left: .5em;
            pointer-events: none;

            //  And fix up the padding
            padding-left: 0px;
            padding-top: 10px;
        }

        li.baselayer .layerup, .ol-layerswitcher li.baselayer .layerdown {
            //  Disable reordering base layers
            display: none;
        }

        .layerswitcher-opacity {
            //  Hide the opacity sliders - no way to do this on specific layers so just hide them all
            display: none;
        }
    }

    .iq-digsite-size-map-control {
        bottom: 10px;
        left: 50%;
        -webkit-transform: translate(-50%,0);
        width: calc(100% - 19em);
        text-align: center;
        outline: none;
        color: red;
        background-color: rgba(255, 255, 255, 0) !important;
        display: flex;
        justify-content: center;

        div {
            background-color: rgba(255, 255, 255, .5);
            padding: 3px 5px 3px 5px;
        }

        div:hover {
            background-color: rgba(255, 255, 255, .8);
        }

        @media screen and (max-width: 600px) {
            //  On phone, the overview map and mouse coords are not displayed so size this to fill the entire bottom of the map.
            width: 100%;
        }
    }

    .ol-overviewmap, .ol-mouse-position {
        @media screen and (max-width: 600px) {
            //  Don't show the overview map button or mouse coordinates on mobile.  Too small to be of much use and takes up space
            //  that's better used for the DigSite Size.
            display: none;
        }
    }

    .ol-ctx-menu-container {
        //  Styles sub menus in the context menu on the map
        .ol-ctx-menu-submenu {
            .ol-ctx-menu-container {
                max-height: 300px; //  Allows a long list to scroll
                overflow-y: auto;
            }
        }

        .iq-no-image {
            > span {
                padding-left: 20px;
            }
        }

        .iq-image-item {
            > span > i {
                width: 20px;
            }
        }

        .manually-added {
            color: red;
            font-weight: bold;
            margin-right: 7px;
        }

        .added-from-search {
            color: red;
            width: 15px !important;
        }
    }
    //  Padding around sub-bars so that multiple bars sitting next to each other have some separation
    .ol-control.ol-bar.ol-group {
        padding-left: 10px;
        padding-right: 10px;
    }

    .ol-control.ol-bar.iq-centered-control-bar-container {
        background-color: unset;
        //  This method of centering is necessary because the default uses left 50% then translate.  That ends up
        //  (silently) resulting in a max width of 50% on the control bar - so more than 6 or so buttons wraps.
        //  This works much better but has a side effect of causing drawing click events to not work allong the top
        //  and bottom of the map (because this div - which is an OpenLayers Control that eats click events) is stretched
        //  across the entire width of the map (less 80px on each side).
        //  Could not find any other way to be able to center this and still allow it to take up enough width to not wrap.
        width: calc(100% - 160px);
        margin-left: 80px;
        display: flex;
        justify-content: center;
        left: unset;
        transform: unset;
        -webkit-transform: unset;
    }
    //  When the map search is visible, this causes the top toolbar to be positioned just to the right of the
    //  map search fly-out - or to remain centered if there is enough room for it to stay visible.
    .map-search-visible {
        .ol-control.ol-bar.ol-top.iq-top {
            //  70px is half the current width of the toolbar.
            //  430px is width plus some padding of the map search fly-out
            //  100%-480px sets the max-width so that it will not overlap the buttons in the top-right (and will cause
            //  the div to wrap the buttons - because of white-space: inherit)
            left: calc(max(50% - 70px, 430px));
            max-width: calc(100% - 480px);
            white-space: inherit;
            transform: unset;
            -webkit-transform: unset;
        }
    }

    .ol-control.ol-bar.iq-centered-control-bar-container:hover {
        background-color: unset;
    }

    .ol-control.ol-bar.iq-control-bar-container {
        background-color: unset;
    }

    .ol-control.ol-bar.iq-control-bar-container:hover {
        background-color: unset;
    }

    .ol-control.ol-bar {
        .ol-control.hidden {
            height: 0px;
        }

        .material-icons {
            font-size: 1em;
            width: unset;
            height: unset;
        }
        //  Hidden controls inside a bar need width = 0 so they actually collapse
        .ol-control.hidden {
            width: 0px;
        }

        .button-spacer-top:not(.hidden) {
            margin-top: 20px;
        }
    }

    .ol-control.ol-bar.ol-top.ol-left {
        //  Needed to move the left control bar down below the search button
        top: 10em;
    }

    .ol-control.ol-zoom {
        //  Position zoom buttons down a bit - the map search is in the top/left corner
        top: 5em;
    }

    .iq-ol-draw-tooltip {
        position: relative;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        color: white;
        padding: 4px 8px;
        opacity: 0.7;
        white-space: nowrap;
        font-size: 12px;
    }

    .iq-ol-draw-tooltip.hidden, .ol-control.hidden {
        visibility: hidden;
    }

    .iq-info-layer-name {
        color: #949494;
        padding-right: 10px;
    }
}
