.iq-device-size-phone {
    .iq-phone-page {
        /*position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;*/
    }

    .iq-phone-page-content {
        /*position: absolute;
        top: 50px;
        right: 0;
        bottom: 50px;
        left: 0;
        overflow: hidden;*/
    }

    mat-toolbar {
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
    }

    .iq-phone-header-left {
        min-width: 40px; //min-width of an icon button
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
    }

    .iq-phone-header-center {
        min-width: 40px; //min-width of an icon button
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    .iq-phone-header-right {
        min-width: 40px; //min-width of an icon button
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
    }

    .iq-response-select {
        max-width: 90vw !important; //Need to do this so that we get as much width as we can because the text in the responses is looooong
    }

    .mat-select-panel {
        //Needed because the the select options will get cut off on smaller phones if turned sideways.
        //  The default size is 256, and some screens are close to that size so when the options are shown it won't fit correctly
        max-height: 50vh !important;

        &.iq-multi-select {
            //Have to do this because it calculates the size wrong on a multi-select and it runs off the screen
            max-width: 95vw !important;
            min-width: unset !important;
        }
    }
}
