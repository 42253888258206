//  This is included in the main styles.scss so that we can apply css styles to the nGrid components.


//  Moves the paginator to be displayed above the grid and to the right side of the page.
pbl-ngrid-paginator {
    position: absolute;
    right: 0;
    background-color:transparent !important;
}

//  Causes long lines to wrap for up to 3 lines.
//  Does cause the width calculations in the grid to not expand the columns as much as they probably could
//  because it does not know about the wrapping.
.pbl-ngrid-cell-ellipsis-3-lines .pbl-ngrid-cell, .pbl-ngrid-header-cell-ellipsis-3-lines .pbl-ngrid-header-cell {
    div {
        //  https://css-tricks.com/almanac/properties/l/line-clamp/
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
