@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin tab-typography($typography) {
    .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
        font-size: mat.font-size($typography, display-1);
        font-weight: mat.font-weight($typography, display-1);
        line-height: mat.line-height($typography, display-1);
    }

    .mat-nav-list .mat-list-item {
        font-size: mat.font-size($typography, display-1);
        font-weight: mat.font-weight($typography, display-1);
        line-height: mat.line-height($typography, display-1);
    }
}
