//Has to be here and not component css because this is done in a overlay outside of the comonent.
//  Also, not sure how helpful this really is because you can't close the overlay by clicking on the control, but it looks better when editing a contact of
//      and just selecting the contact types
.positionDropDownBelow {
    margin-top: 30px !important;
}

/*  Remove the padding below or above the mat controls by applying these classes.  Only use these if you aren't going to show theese things because if they are there it will float over other content  */
mat-form-field.no-hint-or-error {

    .mat-form-field-wrapper {
        padding-bottom: 0px !important;

        .mat-form-field-subscript-wrapper{
            display: none;
        }

        .mat-form-field-underline {
            position: initial;
        }
    }
}
mat-form-field.no-placeholder {

    .mat-form-field-wrapper {
        .mat-form-field-flex {
            .mat-form-field-infix{
                border-top: 0px;
            }
        }
    }
}

/*  Mat form field placeholder labels */
/*.ma*/t-form-field-infix {
    padding: 3px 0 !important;
}

.mat-form-field-subscript-wrapper {
    margin-top: unset !important;
}

/* mat-paginator */
.mat-paginator { //Our background may not be white, so we want to to show whatever the backgroud color is
    background-color: transparent;
}


/**********Drag and drop values.  Used throught the system, if that needs changed to only be in specific places then update this**************/
/* Animate items as they're being sorted. */
.cdk-drop-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    opacity: .5;
}
/**********End Drag and drop**************/


body {
    //  Make the checkboxes a little smaller.  Default is 20x20.
    /*.mat-checkbox-frame {
        width: 15px;
        height: 15px;
        top: 3px;
    }*/
    //  Material sets margin-right to "auto" (for these exact same css classes) which screws up the width calculations
    //  and forces a horizontal scrollbar on the ticket entry page!
    .mat-checkbox-label-before .mat-checkbox-inner-container {
        margin-right: 8px;
    }

    //Need to change the margin of the checkboxes for the OpenTime component so that you can tell what checkbox the text goes with
    .day-checkbox {
        .mat-checkbox-inner-container {
                margin-right: 3px;
        }
    }
}

//  Put this class on an <autocomplete> tag to pick this up.  These are the same styles used on ticket entry form.
//  It sizes everything better and makes the rows auto size.
.iq-autocomplete, .iq-ticket-entry, .iq-entry-form {
    &.mat-autocomplete-panel {
        //Width is done by setting the 'panelWidth' property to auto now.  That way the autocomplete popup will autosize larger or smaller based on what it can do based on the screen size, not input size
        //min-width: 700px; // style left her incase people don't like the auto sizing. min-width needed when we have an autocomplete on an input that is not very wide (like Company ID).
        max-height: 20em; //  default is 256px which makes it scroll most of the time.  There is no setting for "bottom of view"...
    }
    //  This changes the default font and spacing used by plain (non-templated) autocompletes on ticket entry.
    //  The Material defaults have a MASSIVE amount of padding in between items!
    .mat-option.mat-active {
        @extend %mat-option-active;
    }

    .mat-option {
        font-size: 14px !important; //Need to be important so it will work for mat-selects too because they change this property
        height: auto !important; //Need to be important so it will work for mat-selects too because they change this property
        line-height: unset !important; //Need to be important so it will work for mat-selects too because they change this property
        padding: 5px 16px;

        .item {
            padding: 0 4px; //Add a little more padding for these (they are autocomplete items) so that they are indented alittle more than the actions (which will be indented at 16px)
        }
    }
}

//  Put "allow-wrap" on a map-top to allow the content to wrap to another line.
//  Angular's default is to add "white-space:nowrap" in it's ".mat-option" css class.
.allow-wrap.mat-option {
    white-space: initial;
}

//  Put this on a mat-checkbox to allow the checkbox labels to wrap based on the parent width
.nowrap {
    .mat-checkbox-layout {
        .mat-checkbox-label {
            white-space:initial;
        }
    }
}

//  Used on a mat-select element that has a color defined directly on it.  Needed because the material styles
//  hardcode a black color...
//  "not disabled" or the background color will be disabled but the color will not be the dimmed out black.
.inherit-color:not(.mat-select-disabled) {
    .mat-select-value {
        color: inherit !important;
    }
}

//  Needed so that the fields hown in the header of the @matheo/datepicker corrected highlight the component
//  for the current view and the currently picked am/pm indicator.  Without this, all of the fields look the same
//  so you can't tell what is picked.
.mat-calendar-control {
    opacity: .5;

    .mat-custom-control-active {
        opacity: 1;
    }
}

.mat-icon {
    //  Angular 14 added "overflow:hidden" to this and it jacks up the display of the ticket version indicators in the ticket list.
    //  That can be fixed by changing those mat-icon elements to have a style of "flex-shrink:0", but went with this global fix
    //  because I'm not sure what else Angular's change may have affected.  This reverts it back to how it was pre-14.
    overflow:inherit !important;
}


//  Slightly lighter background for mat-chips
.mat-chip.mat-standard-chip {
    background-color: #eaeaea !important;
}

// .mat-button-toggle-appearance-standard
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border:none !important;

    .mat-button-toggle {
        //  border width specified in _actionable-items-theme.scss
        border-radius: 8px;

        .mat-button-toggle-label-content {
            //  Reset all the crazy padding Angular adds
            padding: 0px;
            line-height: inherit;
        }

        .mat-button-toggle-button {
            padding: 5px 8px 5px 8px;
        }
    }
}
