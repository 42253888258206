//$app-primary: map-get($tabs-theme, primary);


mat-tab-group.iq-tab-style {
    .mat-tab-body-wrapper {
        flex-grow: 1; //  Necessary to make tab content expand to full height within the tab
    }

    .mat-tab-label-container {

        .mat-tab-list {
            mat-ink-bar {
                visibility: hidden !important; //  Material sets this in the element style so must !important this
            }

            .mat-tab-labels {
                padding-left: 20px; //Add margin here so that the scrolling when the screen is small will work right
                //Make the tabs rounded and a greyish background when not active
                .mat-tab-label {
                    //  Font size & weight set in _tab-typography.scss
                    height: 35px;
                    min-width: 100px;
                    margin-right: 5px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    opacity: unset; //remove out the opacity
                }

                .mat-tab-label.mat-tab-disabled {
                    opacity: .6; //If disabled add back in opacity so that it looks differnent than normal selectable
                }
            }
        }
    }
}

//  Tabs that fall inside another set of Tabs.  Override styles set above to make them look a little different.
//  Because these tabs are inside other tabs, they are ALWAYS going to pick up those default styles.
//  (tried preventing that with :not(.iq-child-tabs) but that didn't work).
//  So must override or unset everything above or it will be inherited here.
//  ** The ticket entry side content uses this so if any changes are made here, make sure to test that (especially the map tab sizing!)
mat-tab-group.iq-tab-style.iq-child-tabs {
    /*.mat-tab-body-wrapper {
        flex-grow: unset; //  Necessary to make tab content expand to full height within the tab
    }*/
    .mat-tab-label-container {
        padding-left: unset;

        .mat-tab-list {
            mat-ink-bar {
                visibility: unset !important; //  Material sets this in the element style so must !important this
            }
            /*.mat-tab-labels {
                //Make the tabs rounded and a greyish background when not active
                .mat-tab-label {
                    border-top-left-radius: unset;
                    border-top-right-radius: unset;
                }

                .mat-tab-label.mat-tab-disabled {
                    opacity: .6; //If disabled add back in opacity so that it looks differnent than normal selectable
                }
            }*/
        }
    }
}

//  Styles tabs to allow the labels to wrap their text and the tab group height to expand as necessary.
mat-tab-group.iq-tab-style.iq-wrapped-tab-labels {
    .mat-tab-label-content {
        white-space: normal;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .mat-tab-label {
        height: auto !important;
    }
}
