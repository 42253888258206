/* Global ticket creation styles are in /Styles/TicketCreation.scss so that they are shared among multiple components.  */
@use '@angular/material' as mat;

@mixin ticket-details($theme, $grayFontTheme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $grayFontPrimary: map-get($grayFontTheme, primary);
    $grayFontAccent: map-get($grayFontTheme, accent);

    //  ** Everything that is shared for an "entry form" (ticket entry & project entry) is in
    //  Shared/EntryFields/Styles/Entry-Forms-theme.scss

    .disclaimer-header {
        color: mat.get-color-from-palette($primary);
        border-bottom: solid mat.get-color-from-palette($primary, default) 1px;
    }
}
