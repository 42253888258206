@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin page-header-typography($typography) {
    .page-header {
        .header-title-xx {
            font-size: mat.font-size($typography, display-3);
            font-weight: mat.font-weight($typography, display-3);
            line-height: mat.line-height($typography, display-3);
        }

        .header-title-x {
            font-size: mat.font-size($typography, display-2);
            font-weight: mat.font-weight($typography, display-2);
            line-height: mat.line-height($typography, display-2);
        }


        //Only used on old ticket entry forms.  Can be removed once they are removed
        .ticket-entry-field {
            .header-title-x {
                font-size: mat.font-size($typography, display-3);
                font-weight: mat.font-weight($typography, display-3);
                line-height: mat.line-height($typography, display-3);
            }
        }
    }
}
