@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin mat-icons-typography($typography) {
    .material-icons {
        font-size: mat.font-size($typography, body-2);
        font-weight: mat.font-weight($typography, body-2);
        line-height: mat.line-height($typography, body-2) !important;
        height: mat.font-size($typography, body-2);
        width: mat.font-size($typography, body-2);
    }
}
