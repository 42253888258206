@use '@angular/material' as mat;

@mixin icon-button($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .icon-button {
        color: unset;
    }

    .icon-button.disabled {
        fill-opacity: .3;
    }

    //  Style for a button that is in the top header of a page
    .icon-button.header {
        background-color: mat.get-color-from-palette($accent);
        color: mat.get-color-from-palette($accent, 'default-contrast');

        svg {
            color: mat.get-color-from-palette($accent);
        }

        &:hover, &:focus {
            background-color: mat.get-color-from-palette($accent, 'darker');
            color: mat.get-color-from-palette($accent, 'darker-contrast');
        }

        &.disabled {
            background-color: mat.get-color-from-palette($accent, .3);

            svg {
                color: mat.get-color-from-palette($accent, .3);
            }

            &:hover {
                background-color: mat.get-color-from-palette($accent, .3);
            }
        }
    }

    //  Style for a button that is used on a dialog or is just a regular button (at least at the moment, they are the same)
    .icon-button.dialog, .icon-button.button {
        background-color: ButtonFace;

        border: 1px solid lightgray;

        .content {
            color: mat.get-color-from-palette($primary);
        }

        &:hover:not(.disabled), &:focus:not(.disabled) {
            background-color: ButtonHighlight;
            border-color: mat.get-color-from-palette($primary);

            .content {
                text-decoration: underline;
            }
        }

        &.disabled {    
            color: darkgray;
            background-color: snow;

            .content {
                color: darkgray;
            }
        }
    }
}
