/* This is for the ng-pick-datetime calendar control that we may or may not keep... */
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import '@angular/material/theming';
@import 'typography/typography-configs.scss';

%colorGreen {
    color: green;
}

%colorRed {
    color: red;
}

.owl-overlay-container {
    z-index: 2000;
}

html {
    height: 100%;
    overflow: hidden;
}

body, .cdk-overlay-container {
    //Maybe move this to a more approprate place
    .top-add-link {
        padding-bottom: 10px;
    }

    .bottom-add-link {
        padding-top: 10px;
    }

    //  To show Red or Green based on a bool column value, see SearchColumn.DynamicCssClass and use SearchColumn.BoolToRedGreen
    .green {
        @extend %colorGreen;
    }
    .red {
        @extend %colorRed;
    }
    .lightgray {
        color: lightgray;
    }

    //Used on admin pages.  It's the part that isn't the header
    .page-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .tab-group {
            height: 100%;
            flex-grow: 1;
            min-height: 0px; //  Needed for iOS or won't scroll
        }

        .tab-content {
            //padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    height: 100%;
    overflow: hidden;
    margin: 0px;

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .app-content {
        /*padding: 10px;*/
        box-sizing: border-box;

        h1 {
            display: inline-block;
            font-size: 24px;
            font-weight: 500;
            margin: 8px 0px;
        }

        h1:after {
            content: "";
            display: block;
            height: 1px;
            width: 40%;
            margin: 24px 0px 10px;
            background: $lightgray;
            clear: both;
        }
    }
    /*.mat-toolbar {
        .mat-icon {
            cursor: pointer;
        }

        .app-toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-left: 24px;
            margin-right: 8px;

            @media ($xsmall) {
                margin-left: 16px;
                margin-right: 0px;
            }
        }
    }*/
    h1 {
        font-size: 20px;
    }
    //  Keep these inside "body" so that they are more selectable than other default styles (like Angular Materials...)
    .fill-remaining-space {
        flex: 1 1 auto;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }
}

// Stretch to screen size in fullscreen mode.  Also needed to make the page fit in the screen and then
// Scroll just the content (leaving the toolbar fixed at the top).
.app-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.account-card-container {
    display: flex;
    justify-content: center;

    mat-card {
        width: 400px;
    }
}

.has-error {
    color: $brightred;
}

.full-width {
    width: 100%;
}

/*  This is needed for using the hidden directive on elements that may also use display:flex.
    When an element is hidden, it sets display:none which may or may not override the display:flex.
    This css will make sure it's applied with !important so that it will be correctly hidden all the time.
    http://www.talkingdotnet.com/dont-use-hidden-attribute-angularjs-2/
    */
[hidden] {
    display: none !important;
}

.link, .link.mat-button, .link-primary-color {
    text-decoration:underline;
    cursor:pointer;
}
/*.link:focus {
    outline: none;
}*/

.pointer {
    cursor: pointer;
}

//  A column header that does not have any kind of action menu on it - so should not display as a link!
.column-header {
    font-weight: bold;
    text-decoration: underline;
}

//  Not sure what the default min is, but it's WAY too small and truncates web urls displayed on the Comm Activity page.
.mat-tooltip {
    font-size: medium !important; //  default is so small it's harder to see than the text on the page!
    max-width: 50em !important;
    white-space: pre-line; //  to allow line breaks on \n or use "&#13;" in in-line content
}

.iq-header-button-container {
    display: flex;
    padding-bottom: 10px;
    padding-right: 20px;
    justify-content: flex-end;
}

@media print {
    body {
        -webkit-print-color-adjust: exact; //Used in chrome to auto apply colors, so they don't have to check the "Background Graphics" option

        //  Needed in order to be able to print more than 1 page
        height: auto;

        margin: 0;
        padding: 0;
    }

    //  Add this to an element that is in the component being printed that should be hidden when we are printing:
    //      [class.isPrinting]="PrintingService.IsPrinting"
    //      (and obviously, the component needs a public reference to the PrintingService)
    .isPrinting {
        //Have to do important because we wan to force everything else to be hidden except the print layout
        display: none !important;
    }

    //  Or - easier - just add this class to an element that should be hidden any time "@media print" is active!
    .hide-when-printing {
        display: none !important;
    }

    //  Hide the entire application.  The printable content is injected into a "router-outlet" named "print" (in app.component.html)
    //  which sits outside of app-root.  So that will be the only thing left visible when we print something.
    app-root {
        .app-root {
            display:none !important;
        }
    }

    #externalContent {
        //  This is an iframe - exclude it or it shows a weird icon in the bottom-left corner of the last page!
        display: none !important;
    }

    @page {
        //  Can force specific sizes and margin like this.  But it will apply to everything we print.  There doesn't seem to be a way to change
        //  this at the component level!
        size: auto;
        //size: A4;
        //size: portrait;
        //size: landscape;

        //  Top & bottom margins are needed to give some separation to the content and the header/footer.
        //  This is the only way to specify that if the content breaks to another page.
        margin-top: .5in !important;
        margin-bottom: .5in !important;
    }
}

//  iq-section-group adds 20px of padding to the bottom of every section!
//  It should not do that - the SectionGroupContainer should add the padding to all but the last section inside the container.
//  Add "no-bottom-padding" class to an <iq-section-group> element to suppress that
iq-section-group.no-bottom-padding > div:first-child {
    padding-bottom: 0px !important;
}
//  Tried to add padding to all but last like this but doesn't work - I think because there are other elements in between...
/*iq-section-group-container {
    iq-section-group:not(:last-child):after > div:first-child {
        padding-bottom: 100px !important;
    }
}
*/

fieldset {
    border-width: 1px;
    border-style: solid;
    border-radius: 10px; 
}

.no-scroll {
    overflow: hidden !important;
}

.indent-additional-lines {
    padding-left: 2em;
    text-indent: -2em;
}

//  Put this on an element to completely hide it if it is empty.  Useful when the element has padding defined on it
//  to cause that padding to not be applied if it has no content.
.hide-if-empty:empty {
    display:none;
}

//  This hides the LastPass autofill icon globally.
//  This is necessary to prevent it from auto-filling fields we don't want filled - especially on the ticket entry
//  page for excavators where some fields are disabled/readonly!  It is randomly appearing on various inputs no matter
//  what we do.  This was the only option I could find to hide it.
//  https://stackoverflow.com/a/74554486/916949
[data-lastpass-icon-root], [data-lastpass-root] {
    display: none;
}
//  And this (which is more selectable than above), allows it on the login & self registration page.
.iq-aws-cognito-sign-in, iq-aws-cognito {
    [data-lastpass-icon-root], [data-lastpass-root] {
        display: unset;
    }
}
